<template>
  <div class='form-page ym-page' :style="[{height:formHeight+'px'}]">
    <el-row type="flex" justify="space-between">
      <el-col :xs="16" :sm="16" :md="12" :lg="12" :xl="12">
        <ymForm ref="setting"
                :formValue="formValue"
                label-width="150px"
                :formData="formData"
                @onSubmit="onSubmit"></ymForm>
      </el-col>
      <el-col style="width: 200px" v-if="this.getActiveArray('/sys/update-config')" :xs="2" :sm="2" :md="2" :lg="1" :xl="1">
        <el-button class="edit-btn" v-if="readOnly" type="primary" size="small" @click="handleEditor"><i class="ym-icon-bianji btn-icon"></i>&ensp;编 辑</el-button>
      </el-col>
    </el-row>
    <el-row v-if="!readOnly" style="margin-top:100px" type="flex" justify="center">
      <el-button class="btn-left" type="primary" plain @click="handleBack">返 回</el-button>
      <el-button class="btn-right" type="primary" v-loading="loading" @click="submitForm('setting')">提 交</el-button>
    </el-row>
  </div>
</template>

<script>
import ymForm from '@/components/YmForm'
import { setHeaderTitle } from '@/utils/localStorage.js'
import { activeArray } from '../../../../utils/localStorage'

export default {
  components: {
    ymForm
  },
  data() {
    var _this = this

    return {
      formShow: true,
      loading: false,
      readOnly: true, // 是否只读
      labelPosition: 'left',
      formValue: {
        systemName: '',
        domain: '',
        alertConfigId: '',
        receiverType: '',
        url: '',
        userId: '',
        password: ''
      },
      formHeight: '',
      formData: [
        // 表单组
        {
          // 表单组标题
          title: '系统设置',
          // 是否为第一个标题
          firstTitle: true,

          items: {
            systemName: {
              label: '系统名称',
              type: 'text',
              clearable: true,
              placeholder: '请输入',
              showWordLimit: true,
              maxlength: '50',
              readonly: true,
              rules: [
                {
                  required: true,
                  message: '请输入系统名称',
                  trigger: 'change'
                },
                { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
              ]
            },

            domain: {
              label: '域名',
              type: 'text',
              clearable: true,
              placeholder: '请输入',
              readonly: true,
              rules: [
                {
                  required: true,
                  message: '请输入域名',
                  trigger: 'change'
                },
                // eslint-disable-next-line no-useless-escape
                { pattern: /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/, message: '请输入正确的域名格式', trigger: 'change' }
              ]
            },

            receiverType: {
              label: '接收信息类型',
              type: 'select',
              clearable: true,
              disabled: true,
              children: [
                {
                  label: '邮箱',
                  value: 'email'
                }
                // {
                //   label: 'webhook',
                //   value: 'webhook'
                // },
                // {
                //   label: '微信',
                //   value: 'wechat'
                // }
              ],
              rules: [
                { required: true, message: '请选择接收信息类型', trigger: 'change' },
                {
                  validator: function (rule, value, callback) {
                    if (value) {
                      _this.formData[0].items.url.hidden = false
                      // _this.formData[0].items.name.hidden = false
                      _this.formData[0].items.userId.hidden = false
                      if (!_this.readOnly) {
                        _this.formData[0].items.password.hidden = false
                      }
                      return callback()
                    }
                  }
                }
              ],
              changeEvent: (flag, form, group, data) => {
                if (!flag) {
                  _this.formData[0].items.url.hidden = true
                  // _this.formData[0].items.name.hidden = true
                  _this.formData[0].items.userId.hidden = true
                  _this.formData[0].items.password.hidden = true
                }
              }
            },
            url: {
              label: 'SMTP服务器',
              hidden: true,
              type: 'text',
              clearable: true,
              placeholder: '请输入，例子：smtp.exmail.qq.com:465',
              readonly: true,
              rules: [
                {
                  required: true,
                  message: '请输入SMTP服务器',
                  trigger: 'change'
                }
              ]
            },
            userId: {
              label: '邮箱用户名',
              hidden: true,
              type: 'text',
              clearable: true,
              placeholder: '请输入',
              readonly: true,
              rules: [
                {
                  required: true,
                  message: '请输入邮箱用户名',
                  trigger: 'change'
                },
                // eslint-disable-next-line no-useless-escape
                { pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/, message: '请输入正确的邮箱格式', trigger: 'change' }
              ]
            },
            // name: {
            //   label: '邮箱用户名',
            //   hidden: true,
            //   type: 'text',
            //   clearable: true,
            //   placeholder: '请输入',
            //   readonly: true,
            //   rules: [
            //     {
            //       required: true,
            //       message: '请输入邮箱用户名',
            //       trigger: 'change'
            //     }
            //   ]
            // },
            password: {
              label: '邮箱密码',
              hidden: true,
              type: 'password',
              clearable: true,
              placeholder: '请输入',
              // readonly: true,
              rules: [
                {
                  required: true,
                  message: '请输入邮箱密码',
                  trigger: 'change'
                }
              ],
              // 由于给表单新增了password一项，所以当清除密码时无法校验需要通过触发事件去清空校验
              changeEvent: (flag, form, group, data) => {
                if (!flag) {
                  this.$delete(this.formValue, 'password')
                }
              }
            }
          }
        }
      ]
    }
  },
  created() {
    this.formHeight = window.innerHeight - 120
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 权限判断
    getActiveArray(value) {
      return activeArray(value)
    },
    // 编辑表单
    handleEditor() {
      this.readOnly = false
      // 因为readonly只接受布尔值，所以只能每一项去设置
      this.formData[0].items.systemName.readonly = false
      this.formData[0].items.domain.readonly = false
      this.formData[0].items.receiverType.disabled = false
      this.formData[0].items.url.readonly = false
      // this.formData[0].items.name.readonly = false
      this.formData[0].items.userId.readonly = false
      if (this.formValue.receiverType === 'email') {
        this.formData[0].items.password.hidden = false
      }
    },

    // 返回
    handleBack() {
      // 重置表单并清除校验结果
      this.$refs.setting.resetFields()
      this.readOnly = true
      // 因为readonly只接受布尔值，所以只能每一项去设置
      this.formData[0].items.systemName.readonly = true
      this.formData[0].items.domain.readonly = true
      this.formData[0].items.receiverType.disabled = true
      this.formData[0].items.url.readonly = true
      // this.formData[0].items.name.readonly = true
      this.formData[0].items.userId.readonly = true
      this.formData[0].items.password.hidden = true
      // 刷新数据
      this.getData()
    },

    // 提交表单
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    async onSubmit(form) {
      if (this.loading) {
        return
      }
      var systemName = form.systemName
      if (form.password !== '1234567890') {
        this.loading = true
        const { code, message } = await this.$api.setting.updateConfig(form)
        if (code === 200) {
          this.$message.success('提交成功！')
          this.handleBack()
          setHeaderTitle(systemName)
        } else {
          this.$message.error(message)
          this.handleBack()
        }
      } else {
        let _form = JSON.parse(JSON.stringify(form))
        this.$delete(_form, 'password')
        const { code, message } = await this.$api.setting.updateConfig(_form)
        if (code === 200) {
          this.$message.success('提交成功！')
          this.handleBack()
          setHeaderTitle(_form.systemName)
        } else {
          this.$message.error(message)
          this.handleBack()
        }
      }
      setTimeout(() => {
        this.loading = false
        location.reload()
      }, 1000)
    },
    // 查询系统配置
    async getData() {
      const { code, data } = await this.$api.setting.getConfig()
      if (code === 200) {
        // 表单赋值
        this.formValue.domain = data.domain || ''
        this.formValue.systemName = data.systemName || ''
        this.formValue.systemConfigId = data.systemConfigId
        if (data.alertConfigs !== []) {
          this.formValue.alertConfigId = data.alertConfigs[0].alertConfigId
          this.formValue.receiverType = data.alertConfigs[0].receiverType
          this.formValue.url = data.alertConfigs[0].url || ''
          this.formValue.userId = data.alertConfigs[0].userId || ''
        }
        if (data.alertConfigs[0].receiverType === 'email') {
          this.formData[0].items.url.hidden = false
          // this.formData[0].items.name.hidden = false
          this.formData[0].items.userId.hidden = false
          // this.formData[0].items.password.hidden = false
        }
        if (data.alertConfigs[0].userId) {
          this.formValue['password'] = '1234567890'
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/.ym-form .ym-form-title {
  margin-bottom: 20px;
  margin-top: 6px !important;
}
.form-page {
  background: #fff;
  padding: 16px 20px;
}
/deep/.el-form .el-input input.el-input__inner {
  width: 100%;
}
/deep/.el-select {
  width: 100%;
}
.edit-btn {
  position: absolute;
  right: 0;
}
/deep/.el-button--small {
    font-size: 14px;
}
.btn-icon {
  font-size: 14px;
}
.btn-left {
  width: 100px;
}
.btn-right {
  width: 100px;
  margin-left: 40px;
}
</style>
